<template>
  <section class="cont">
    <!-- 面包屑 -->
    <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>数据中台</el-breadcrumb-item>
          <el-breadcrumb-item>储蓄卡</el-breadcrumb-item>
          <el-breadcrumb-item>充值记录</el-breadcrumb-item>
        </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>手机号：</label>
            <el-input v-model="param.mobile" placeholder="请输入手机号"></el-input>
          </el-row>
          <el-row class="search-item">
            <label>用户ID：</label>
            <el-input v-model="param.memberId" placeholder="请输入用户ID"></el-input>
          </el-row>
          <el-row class="search-item">
            <label>充值时间：</label>
             <date-packer class="width-260" :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
          </el-row>
          <el-button type="primary" @click="handleQuery(true)">搜索</el-button>
          <el-button @click="handleQuery(false)">重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-table
          v-loading="loading"
          :data="tableData"
          border fit
          style="width: 100%"
          :stripe="true"
          :header-cell-style="{background: '#f5f8f9'}">
          <el-table-column type="index" :index="1" label="序号" width="60"></el-table-column>
          <el-table-column prop="createTime" label="充值时间"></el-table-column>
          <el-table-column prop="memberId" label="用户ID"></el-table-column>
          <el-table-column prop="mobile" label="用户手机号"></el-table-column>
          <el-table-column label="充值类型">
            <template slot-scope="scope">
              {{scope.row.type | cardTypeFilter}}
            </template>
          </el-table-column>
          <el-table-column prop="payAmount" label="充值金额"></el-table-column>
          <el-table-column prop="donateAmount" label="赠送金额"></el-table-column>
          <el-table-column prop="balance" label="余额"></el-table-column>
          <el-table-column prop="payNum" label="数量"></el-table-column>
          <el-table-column prop="basePayAmount" label="充值基数"></el-table-column>
        </el-table>
        <pagination :total="total" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPag"/>
      </el-row>
    </el-row>
  </section>
</template>
<script>
import { urlObj } from '@/api/interface';
import { depositCard } from "@/api/interface/data";
import { mapState } from 'vuex'
export default {
   data(){
      return{
         tableData: [],
         loading: false,
         total: 0,
         param: {
            mobile:"",
            memberId:"",
            beginTime:null,
            endTime:null,
            limit: 0,
            page: 1,
            typeList:"OPEN_CARD,DAILY"
         },
         dateValue: []
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted(){
      this.param.limit = sessionStorage.getItem('pageSize') * 1
      this.getPayRecord()
   },
   methods:{
      // 获取充值记录
      getPayRecord() {
         this.loading = true;
         const url = depositCard.payRecords
         const param = { companyId: this.hotelInfo.storeId, ...this.param }
         this.$axios.get(url, param).then(res => {
            this.loading = false;
            if (res.success) {
               this.total = res.total;
               this.tableData = res.records;
            }
         })
      },
      // 搜索记录
      handleQuery(bool) {
         this.param.page = 1
         if (bool) return this.getPayRecord()
         this.param.memberId = ''
         this.param.mobile = ''
         this.getDatePacker([])
      },
      // 获取时间段
      getDatePacker(val){
         this.dateValue = val
         let [ beginTime = null, endTime = null ] = val
         this.param.beginTime = beginTime
         this.param.endTime = endTime
      },
      // 改变每页数
      changePagNum(num) {
         this.param.limit = num;
         this.getPayRecord()
      },
      // 改变当前页
      changeCurrPag(num) {
         this.param.page = num
         this.getPayRecord()
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getPayRecord()
      }
   },
   filters:{
      cardTypeFilter(val){
         return val == "OPEN_CARD" ? "开卡" : "日常";
      }
   }
}
</script>
